import * as React from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { PageProps, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Button, IconButton, Link } from 'gatsby-material-ui-components';
import { Box, Breakpoint, Grid, Stack, SvgIconProps, Typography, TypographyProps } from '@mui/material';
import StandardFrame from '../components/StandardFrame';
import PageSection from '../components/Sections/PageSection';
import PageSectionTitle from '../components/Sections/PageSectionTitle';
import PricingTypeCard from '../components/Cards/PricingTypeCard';
import { T, N, X, columns, data } from '../../content/inne/cennik';

import CheckIcon from '@mui/icons-material/Check';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PhoneIcon from '@mui/icons-material/Phone';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { CennikCooperationStartSection } from '../sections/cennik/CennikCooperationStartSection';
import { CennikImplementationProfitsSection } from '../sections/cennik/CennikImplementationProfitsSection';
import AdvantageSectionContent from '../components/Sections/Advantages/AdvantageSectionContent';
import CennikDataMigrationSection from '../sections/cennik/data-migration';
import Header from '../sections/cennik/naglowek';
import { ContactForm } from '../components/Sections/ContactForm';

const icons: { [cell: string]: (fontSize: SvgIconProps['fontSize']) => React.ReactElement } = {
  [T]: (_) => <CheckIcon fontSize={_} />,
  [N]: () => <></>,
  [X]: () => <></>
};

const rows = data.flatMap((_) => Object.keys(_.rows));

const flatData = {
  types: data.flatMap((_) => _.types),
  prices: {
    base: data.flatMap((_) => _.prices.base),
    hour: data.flatMap((_) => _.prices.hour)
  },
  rows: rows.map((row) =>
    data.flatMap((_) => _.rows[row as keyof typeof _.rows] ?? _.types.map(() => columns.map(() => X)))
  )
};

const images = [
  <StaticImage
    key={'gabinet-lekarski'}
    src='../images/cennik_gabinet_lekarski.jpg'
    placeholder='tracedSVG'
    layout='fullWidth'
    height={100}
    alt='Gabinet lekarski'
  />,
  <StaticImage
    key={'gabinet_stomatologiczny'}
    src='../images/cennik_pielegniarki_i_polozne.jpg'
    placeholder='tracedSVG'
    layout='fullWidth'
    height={100}
    alt='Pielegniarki i położne'
  />,
  <StaticImage
    key={'gabinet_fizjoterapeutyczny'}
    src='../images/cennik_gabinet_fizjoterapeutyczny.jpg'
    placeholder='tracedSVG'
    layout='fullWidth'
    height={100}
    alt='Gabinet fizjoterapeutyczny'
  />,
  <StaticImage
    key={'przychodnia'}
    src='../images/cennik_przychodnia.jpg'
    placeholder='tracedSVG'
    layout='fullWidth'
    height={100}
    alt='Przychodnia'
  />
];

const PricingTable = (tableProps: { selectedType: number; highlightedColumn: number; breakpoint: Breakpoint }) => {
  const displayProp = (breakpointOverride: boolean) => ({
    xs: breakpointOverride ? 'table-cell' : 'none',
    [tableProps.breakpoint]: 'table-cell'
  });

  const PricingCell = (
    props: React.PropsWithChildren<{
      component: 'th' | 'td';
      padding?: 'top' | 'bottom';
      breakpointOverride?: boolean;
      bgcolor?: TypographyProps['bgcolor'];
      color?: TypographyProps['color'];
      highlighted: boolean;
      title?: TypographyProps['title'];
      textAlign?: TypographyProps['textAlign'];
      textTransform?: TypographyProps['textTransform'];
      noWrap?: TypographyProps['noWrap'];
    }>
  ) => (
    <Typography
      component={props.component}
      {...(!props.highlighted && props.padding === 'top'
        ? { borderTop: { [tableProps.breakpoint]: '48px solid white' } }
        : {})}
      {...(!props.highlighted && props.padding === 'bottom'
        ? { borderBottom: { [tableProps.breakpoint]: '48px solid white' } }
        : {})}
      display={displayProp(props.breakpointOverride ?? true)}
      title={props.title}
      bgcolor={props.highlighted ? 'primary.main' : props.bgcolor ?? 'tertiary.main'}
      color={props.highlighted ? 'primary.contrastText' : props.color ?? 'tertiary.contrastText'}
      py={props.highlighted ? { xs: 1, [tableProps.breakpoint]: 1 } : { xs: 1, [tableProps.breakpoint]: 2 }}
      px={props.highlighted ? { xs: 2, [tableProps.breakpoint]: 4 } : { xs: 2, [tableProps.breakpoint]: 3 }}
      variant={props.highlighted ? 'h4' : 'h5'}
      fontWeight='bold' // == th
      textAlign={props.textAlign ?? 'center'}
      textTransform={props.textTransform}
      noWrap={props.noWrap}
    >
      {props.children}
    </Typography>
  );

  const RowSeparator = (props: { breakpointOverride?: boolean }) => (
    <Box
      component='td'
      display={displayProp(props.breakpointOverride ?? true)}
      py={{ xs: 1, [tableProps.breakpoint]: 2 }}
    />
  );

  const ColumnSeparator = (props: { breakpointOverride?: boolean }) => (
    <Box
      component='td'
      display={displayProp(props.breakpointOverride ?? true)}
      px={{ xs: 1, [tableProps.breakpoint]: 2 }}
    />
  );

  const TopDividingRow = (props: React.PropsWithChildren<unknown>) => (
    <Box component='tr' sx={{ borderTopStyle: 'solid', borderTopColor: 'divider' }}>
      {props.children}
    </Box>
  );

  const BottomDividingRow = (props: React.PropsWithChildren<unknown>) => (
    <Box component='tr' sx={{ borderBottomStyle: 'solid', borderBottomColor: 'divider' }}>
      {props.children}
    </Box>
  );

  const [mobileColumn, setMobileColumn] = useState<number>(tableProps.highlightedColumn);

  const PreviousMobileColumnButton = (props: { highlighted: boolean }) => (
    <IconButton
      onClick={() => setMobileColumn(mobileColumn - 1)}
      disabled={mobileColumn === 0}
      sx={{ display: { xs: 'initial', [tableProps.breakpoint]: 'none' } }}
      size={props.highlighted ? 'large' : 'medium'}
    >
      <NavigateBeforeIcon />
    </IconButton>
  );

  const NextMobileColumnButton = (props: { highlighted: boolean }) => (
    <IconButton
      onClick={() => setMobileColumn(mobileColumn + 1)}
      disabled={mobileColumn === columns.length - 1}
      sx={{ display: { xs: 'initial', [tableProps.breakpoint]: 'none' } }}
      size={props.highlighted ? 'large' : 'medium'}
    >
      <NavigateNextIcon />
    </IconButton>
  );

  const HeaderCell = (
    props: React.PropsWithChildren<{
      column: number;
      padding: 'top' | 'bottom';
    }>
  ) => (
    <PricingCell
      component='th'
      padding={props.padding}
      breakpointOverride={props.column === mobileColumn}
      highlighted={props.column === tableProps.highlightedColumn}
      textTransform='uppercase'
      noWrap={true}
    >
      <PreviousMobileColumnButton highlighted={props.column === tableProps.highlightedColumn} />
      {props.children}
      <NextMobileColumnButton highlighted={props.column === tableProps.highlightedColumn} />
    </PricingCell>
  );

  const DataCell = (
    props: React.PropsWithChildren<{
      column: number;
      title?: TypographyProps['title'];
      noWrap?: TypographyProps['noWrap'];
    }>
  ) => (
    <PricingCell
      component='td'
      breakpointOverride={props.column === mobileColumn}
      highlighted={props.column === tableProps.highlightedColumn}
      noWrap={props.noWrap}
    >
      {props.children}
    </PricingCell>
  );

  const currency = new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' });

  return (
    <Box component='table' display='block' sx={{ borderCollapse: 'collapse' }}>
      <thead>
        <BottomDividingRow>
          <ColumnSeparator />
          <RowSeparator />
          {columns.map((column, index) => (
            <>
              <ColumnSeparator breakpointOverride={index === 0} />
              <HeaderCell column={index} padding='top'>
                {column}
              </HeaderCell>
            </>
          ))}
          <ColumnSeparator />
        </BottomDividingRow>
      </thead>
      <tbody>
        <BottomDividingRow>
          <ColumnSeparator />
          <PricingCell component='th' bgcolor='transparent' highlighted={false} textAlign='left'>
            Abonament miesięczny
          </PricingCell>
          {flatData.prices.base[tableProps.selectedType].map((base, index) => (
            <>
              <ColumnSeparator breakpointOverride={index === 0} />
              <DataCell column={index} noWrap={true}>
                {currency.format(base)}
              </DataCell>
            </>
          ))}
          <ColumnSeparator />
        </BottomDividingRow>
        {flatData.rows.map(
          (row, index) =>
            row[tableProps.selectedType].every((cell) => cell !== X) && (
              <tr key={index}>
                <ColumnSeparator />
                <PricingCell component='th' bgcolor='transparent' highlighted={false} textAlign='left'>
                  {rows[index]}
                </PricingCell>
                {row[tableProps.selectedType].map((cell, index) => (
                  <>
                    <ColumnSeparator breakpointOverride={index === 0} />
                    <DataCell column={index} title={rows[index]}>
                      {icons[cell](index === tableProps.highlightedColumn ? 'large' : 'medium')}
                    </DataCell>
                  </>
                ))}
                <ColumnSeparator />
              </tr>
            )
        )}
        <TopDividingRow>
          <ColumnSeparator />
          <PricingCell component='th' bgcolor='transparent' highlighted={false} textAlign='left'>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Box>Inne funkcje</Box>
              <IconButton to='/funkcje' title='Sprawdź inne dostępne funkcje systemu'>
                <OpenInNewIcon />
              </IconButton>
            </Stack>
          </PricingCell>
          {columns.map((column, index) => (
            <>
              <ColumnSeparator breakpointOverride={index === 0} />
              <PricingCell
                key={index}
                component='td'
                breakpointOverride={index === mobileColumn}
                highlighted={index === tableProps.highlightedColumn}
                title={rows[index]}
              >
                <PhoneIcon fontSize={index === tableProps.highlightedColumn ? 'large' : 'medium'} />
              </PricingCell>
            </>
          ))}
          <ColumnSeparator />
        </TopDividingRow>
      </tbody>
      <tfoot>
        <TopDividingRow>
          <ColumnSeparator />
          <RowSeparator />
          {columns.map((column, index) => (
            <>
              <ColumnSeparator breakpointOverride={index === 0} />
              <HeaderCell column={index} padding='bottom'>
                {column}
              </HeaderCell>
            </>
          ))}
          <ColumnSeparator />
        </TopDividingRow>
      </tfoot>
    </Box>
  );
};

export const pageQuery = graphql`
  query Pricing {
    profits: allZyskiZWdrozeniaYaml {
      edges {
        node {
          id
          title
        }
      }
    }
    dataMigrationSystems: allMigracjaDanychSystemyYaml {
      edges {
        node {
          id
          title
        }
      }
    }
    dataMigrationData: allMigracjaDanychDaneYaml {
      edges {
        node {
          id
          title
        }
      }
    }
    advantages: allPrzewagiYaml(filter: { tags: { in: ["general"] } }) {
      edges {
        node {
          id
          title
          description
        }
      }
    }
    testimonial: allMarkdownRemark(filter: { childTestimonial: { id: { ne: null } } }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            name
            captions
            photo {
              childImageSharp {
                gatsbyImageData(
                  layout: FIXED
                  width: 250
                  quality: 95
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          html
        }
      }
    }
  }
`;

export default ({
  data: { profits, advantages, dataMigrationData, dataMigrationSystems }
}: PageProps<Queries.PricingQuery>) => {
  console.log(flatData);
  const [selectedType, setSelectedType] = React.useState<number | null>(null);

  return (
    <>
      <Helmet title='Oprogramowanie dla gabinetów i przychodni' />
      <StandardFrame>
        <Header />
        <PageSection bgcolor='tertiary.main' maxWidth='lg'>
          <PageSectionTitle>Wybierz swój rodzaj działalności</PageSectionTitle>
          <Grid container spacing={3}>
            {flatData.types.map((type, index) => (
              <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                <PricingTypeCard
                  title={type}
                  image={images[index]}
                  selected={index === selectedType}
                  onSelect={() => setSelectedType(index)}
                />
              </Grid>
            ))}
          </Grid>
        </PageSection>
        {selectedType != null && (
          <PageSection maxWidth='lg' id='oferty'>
            <PageSectionTitle>Wstępna propozycja</PageSectionTitle>
            <Typography>
              Na podstawie naszego doświadczenia we wdrażaniu oprogramowania w placówkach o podobnym profilu
              przygotowaliśmy trzy podstawowe warianty oferty. Ich szczegóły omówimy podczas spotkania on-line, aby
              wspólnie wybrać najlepsze rozwiązanie lub przygotować ofertę dopasowaną do indywidualnych potrzeb.
            </Typography>
            <Box my={{ xs: 1, sm: 2, md: 3 }} display='flex' justifyContent='center'>
              <PricingTable selectedType={selectedType} highlightedColumn={1} breakpoint='md' />
            </Box>
            <Typography>
              Możesz również wyjść poza ramy gotowych pakietów i zamówić dokładnie takie usługi, jakich potrzebujesz.
              Wszystko w ramach elastycznego rozliczania opłat, zależnego od poziomu wykorzystania systemu.
            </Typography>
            <Stack alignItems={'center'} mt={{ xs: 3, lg: 4 }}>
              <Link sx={{ minWidth: '40%' }} to='#formularz-kontaktowy'>
                <Button size='large' fullWidth>
                  Umów spotkanie
                </Button>
              </Link>
            </Stack>
          </PageSection>
        )}
        <CennikImplementationProfitsSection profits={profits.edges.map((e) => e.node.title!)} />
        <CennikCooperationStartSection />
        <PageSection bgcolor='tertiary.main' maxWidth='lg'>
          <PageSectionTitle>Dlaczego warto wybrać Mediportę</PageSectionTitle>
          <AdvantageSectionContent
            spacing={3}
            items={advantages.edges.map(({ node }) => ({
              id: node!.id!,
              title: node!.title!,
              description: node!.description!
            }))}
          />
        </PageSection>
        <CennikDataMigrationSection
          data={dataMigrationData.edges.map((e) => e.node.title!)}
          systems={dataMigrationSystems.edges.map((e) => e.node.title!)}
        />
        <ContactForm bgcolor={'tertiary.main'} />
      </StandardFrame>
    </>
  );
};
